* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  min-height: 100%;
}
body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}
.layout {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #343b4c;
  min-height: 100%;
  font-weight: 500;
}
.hidden {
  display: none;
}
:focus {
  outline: none;
}
button {
  border-color: inherit;
  border-width: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
}
table.ui {
  width: 100%;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  background-color: #fafafa;
  border-collapse: collapse;
  border-spacing: 0;
}
table.ui th {
  height: 50px;
  padding: 0 5px;
  color: #fff;
  background-color: #315efb;
}
table.ui th:first-child {
  padding-left: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
table.ui th:last-child {
  padding-right: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
table.ui tr {
  background-color: #f7faff;
  border-bottom: 1px solid #e0ebff;
}
table.ui td {
  padding: 13px 5px;
  color: #343b4c;
}
table.ui td:first-child {
  padding-left: 15px;
}
table.ui td:last-child {
  padding-right: 15px;
}
table.ui td a {
  color: #343b4c;
  text-decoration: none;
}
